import { ProductSearchParams } from "@/app/products/_types";
import { isValidUrl } from "@/components/Header/SearchBar/utils";
import usePhiaSearchParams from "./usePhiaSearchParams";

export const useLinkPasting = () => {
  // [PHIA-2392: Deprecate old product search code and link pasting flag once switchover happens to Google]
  // (https://linear.app/phiaco/issue/PHIA-2392/deprecate-old-product-search-code-and-link-pasting-flag-once)
  const isLinkPastingEnabled = true;
  const searchParams = usePhiaSearchParams();
  const link = searchParams.get(ProductSearchParams.LINK) || "";
  const showLinkPastingView = isValidUrl(link) && isLinkPastingEnabled;

  return {
    isLinkPastingEnabled,
    showLinkPastingView,
  };
};
