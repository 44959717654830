"use client";

import {
  LOG_UNAUTHED_SEARCH_COOKIE_NAME,
  NUM_DAYS_UNTIL_EXPIRES,
  SEARCH_LIMIT,
} from "@/components/Header/SearchBar/utils";
import { useAuthContext } from "@/context/AuthContext";
import { routes } from "@/routes";
import Cookies from "js-cookie";
import { usePathname } from "next/navigation";

export const useUnauthedSearchCount = () => {
  const pathname = usePathname();
  const { isLoggedIn } = useAuthContext();

  const searchCount = Cookies.get(LOG_UNAUTHED_SEARCH_COOKIE_NAME);
  const currentCount = searchCount ? parseInt(searchCount) : 0;
  const isSearchLimitReached = currentCount >= SEARCH_LIMIT;
  const isProductsPage = pathname?.includes(routes.PRODUCTS);
  const shouldCloseModal =
    !isProductsPage || (isProductsPage && !isSearchLimitReached);

  const isSearchLimitReachedAndUnauthed = isSearchLimitReached && !isLoggedIn;

  const setCookie = () => {
    // If the user is not logged in, handle unauthenticated searches
    if (!isLoggedIn) {
      // Get the current search count from the cookie
      const searchCount = Cookies.get(LOG_UNAUTHED_SEARCH_COOKIE_NAME);
      const currentCount = searchCount ? parseInt(searchCount) : 0;

      // If the search count exceeds the limit, exit
      if (currentCount >= SEARCH_LIMIT) return;

      // Increment the search count and set it back to the cookie
      Cookies.set(
        LOG_UNAUTHED_SEARCH_COOKIE_NAME,
        (currentCount + 1).toString(),
        {
          expires: NUM_DAYS_UNTIL_EXPIRES,
        }
      );
    }
  };

  return {
    currentCount,
    shouldCloseModal,
    showLimitExhaustedMessage: !shouldCloseModal && !isLoggedIn,
    setCookie,
    isSearchLimitReachedAndUnauthed,
  };
};
