import useLayoutSpacing from "@/hooks/useLayoutSpacing";
import { twMerge } from "tailwind-merge";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const Container: React.FC<Props> = ({ className, children }) => {
  const { headerHeight } = useLayoutSpacing();
  return (
    <div
      style={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
      className={twMerge(
        "no-scrollbar mx-auto flex min-h-screen w-full max-w-[1400px] flex-col p-4 sm:p-10",
        className
      )}>
      {children}
    </div>
  );
};

export default Container;
