import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => Sentry.captureMessage(message));
  if (networkError) {
    Sentry.captureException(networkError);
  }
});

// Custom Apollo Link to add Sentry tracing
const sentryLink = new ApolloLink((operation, forward) => {
  const opDefinitions = operation.query.definitions;
  // @ts-expect-error this will either be query or mutation
  const operationType = opDefinitions.find(def => def.operation).operation;
  let newSpan: Sentry.Span;
  Sentry.startSpanManual(
    {
      op: "contentful-graphql",
      name: `contentful-gql.${operation.operationName}`,
      startTime: operation.getContext().startTime,
    },
    span => {
      span.setAttribute("graphql.type", operationType);
      span.setAttribute("graphql.name", operation.operationName);
      span.setAttributes(operation.variables);
      newSpan = span;
    }
  );
  return forward(operation).map(data => {
    newSpan.setAttributes({
      // TODO: investigate if we want to set `any` here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      "graphql.response": data.data as any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      "graphql.errors": data.errors as any,
    });
    newSpan.end();
    return data;
  });
});

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  },
  fetchOptions: {
    cache: "force-cache",
  },
});

export const contentfulClient = new ApolloClient({
  link: from([sentryLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
  ssrMode: typeof window === "undefined", // enables server-side rendering optimizations
});
