"use client";

import { setShowSignInModalState } from "@/lib/graphql/state";
import { routes } from "@/routes";
import { MixpanelEventName, SigninModalSource } from "@/types";
import { useRouter } from "next/navigation";
import useAnalytics from "./useAnalytics";
import usePhiaPathname from "./usePhiaPathname";
import usePhiaSearchParams from "./usePhiaSearchParams";

interface SigninModalHook {
  handleSignIn: () => void;
  onCloseSignInModal: () => void;
  handleJoinClicked: () => void;
}

export const useSigninModal = (
  signinModalSource?: SigninModalSource
): SigninModalHook => {
  const searchParams = usePhiaSearchParams();
  const pathname = usePhiaPathname();
  const router = useRouter();
  // Using Anonymouse Event since user will not be signed in yet
  const { logMixpanelEvent } = useAnalytics();
  const queryString = new URLSearchParams(searchParams).toString();
  const fullUrl = `${pathname}${queryString ? `?${queryString}` : ""}`;

  const handleSignIn = () => {
    setShowSignInModalState({
      show: true,
      source: fullUrl,
    });
    logMixpanelEvent({
      name: MixpanelEventName.SIGN_IN_MODAL_VIEWED,
      properties: { modalSource: signinModalSource ?? "" },
    });
  };

  const onCloseSignInModal = () => {
    setShowSignInModalState({ show: false, source: fullUrl });
    logMixpanelEvent({
      name: MixpanelEventName.SIGN_IN_MODAL_CLOSE_BTN_CLICKED,
    });
  };

  const handleJoinClicked = () => {
    logMixpanelEvent({
      name: MixpanelEventName.JOIN_PAGE_VISITED,
      properties: { source: signinModalSource ?? "" },
    });
    setShowSignInModalState({
      show: false,
      source: fullUrl,
    });
    router.push(routes.MARKETPLACE_ONBOARDING_GET_STARTED);
  };

  return { handleSignIn, onCloseSignInModal, handleJoinClicked };
};
