import { SearchOverlay } from "@/__generated__/graphql";
import { ApolloClient, gql, NormalizedCacheObject } from "@apollo/client";

const searchOverlayQuery = gql`
  query GetSearchOverlay {
    searchOverlayCollection(limit: 1) {
      items {
        sys {
          id
        }
        popularSearchTerms
        editorialsHeading
        editorialsCollection(limit: 10) {
          items {
            heading
            slug
            label
            image {
              url
            }
          }
        }
        brandsHeading
        brandsCollection(limit: 10) {
          items {
            heading
            slug
            brandTile {
              url
            }
            logo {
              url
            }
            imagesCollection {
              items {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export async function getSearchOverlay(
  client: ApolloClient<NormalizedCacheObject>
): Promise<{
  isLoading: boolean;
  searchOverlay: SearchOverlay | undefined;
}> {
  const { data, loading } = await client.query({
    query: searchOverlayQuery,
  });

  const items = data.searchOverlayCollection.items;
  const searchOverlay = items.length ? items[0] : undefined;

  return {
    isLoading: loading,
    searchOverlay,
  };
}
