"use client";

import { SearchOverlay as SearchOverlayType } from "@/__generated__/graphql";
import { getSearchOverlay } from "@/api/contentful/getSearchOverlay";
import { contentfulClient } from "@/lib/contentful/contentfulApolloClient";
import {
  setSearchOverlayDataState,
  useSearchOverlayDataState,
} from "@/lib/graphql/state";
import { routes } from "@/routes";
import { usePathname } from "next/navigation";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuthContext } from "./AuthContext";

interface SearchContextProps {
  isSearchOverlayVisible: boolean;
  showSearchOverlay: () => void;
  hideSearchOverlay: () => void;
  searchOverlayData: SearchOverlayType | undefined;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchContext = createContext<SearchContextProps | undefined>(undefined);

export const SearchProvider = ({ children }: { children: ReactNode }) => {
  const searchOverlayData = useSearchOverlayDataState();
  const { user } = useAuthContext();
  const [isSearchOverlayVisible, setSearchOverlayVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const pathname = usePathname();

  const showSearchOverlay = () => setSearchOverlayVisible(true);
  const hideSearchOverlay = () => setSearchOverlayVisible(false);

  useEffect(() => {
    // Fetch the Contentful data only once and ensure we're logged in
    if (!searchOverlayData && user) {
      (async () => {
        try {
          const { searchOverlay } = await getSearchOverlay(contentfulClient);
          setSearchOverlayDataState(searchOverlay ?? undefined);
        } catch (error) {
          console.error("Failed to fetch search overlay data:", error);
        }
      })();
    }
  }, [searchOverlayData, user]);

  // Hide search overlay on route change
  useEffect(() => {
    hideSearchOverlay();
    // Clear search term when user navigates to home
    if (pathname === routes.HOME) {
      setSearchTerm("");
    }
  }, [pathname]);

  return (
    <SearchContext.Provider
      value={{
        isSearchOverlayVisible,
        showSearchOverlay,
        hideSearchOverlay,
        searchOverlayData,
        searchTerm,
        setSearchTerm,
      }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
