import { routes, ROUTES_TO_HIDE_SEARCH } from "@/routes";
import { useTailwindBreakpoint } from "@phiaco/phia-ui";
import { matchRoute } from "@phiaco/phia-ui/dist/util";
import { useEffect, useMemo, useState } from "react";
import { useHomeRevamp } from "./useNewHomepage";
import usePhiaPathname from "./usePhiaPathname";

// The following custom hook returns what the header height should be depending on which page we are on
const useLayoutSpacing = (): {
  headerHeight: number;
  isScrolled: boolean;
  landingScrolled: boolean;
} => {
  const { screenSize, isMobile } = useTailwindBreakpoint();
  const pathname = usePhiaPathname();
  const { isRevampActive } = useHomeRevamp();
  const [isScrolled, setIsScrolled] = useState(false);
  const [landingScrolled, setLandingScrolled] = useState(false);
  // Hide searchbar in revamp on homepage mobile view when not scrolled
  const homepageHeightMobile = pathname === routes.HOME && isMobile;
  const onlyShowSearchBarMobile = isScrolled && isMobile;

  useEffect(() => {
    // This function checks the current scroll position and updates the isScrolled state (if it's more than 40px then we set isScrolled to true i.e. hide the top nav and category menu)
    const handleScroll = () => {
      const searchbarPosition = isRevampActive ? 300 : 450;
      // isScrolledPosition is used only on mobile
      const isScrolledPosition =
        pathname === routes.HOME && isRevampActive ? 232 : 40;
      setIsScrolled(window.scrollY > isScrolledPosition);
      setLandingScrolled(window.scrollY > searchbarPosition);
    };
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isRevampActive, pathname]);

  const headerHeight = useMemo(() => {
    const showSearchBar = !matchRoute(
      pathname,
      ROUTES_TO_HIDE_SEARCH[screenSize]
    );
    if (onlyShowSearchBarMobile) {
      return isRevampActive ? 88 : 76;
    }
    if (homepageHeightMobile) {
      return isRevampActive ? 72 : 116;
    }
    if (showSearchBar && isMobile) {
      return isRevampActive ? 128 : 120;
    }
    return isRevampActive ? 98 : 76;
  }, [
    isMobile,
    isRevampActive,
    homepageHeightMobile,
    screenSize,
    pathname,
    onlyShowSearchBarMobile,
  ]);

  return { headerHeight, isScrolled, landingScrolled };
};

export default useLayoutSpacing;
