import useAnalytics from "@/hooks/useAnalytics";
import { LogEventProperties, MixpanelEventName } from "@/types";
// eslint-disable-next-line no-restricted-imports
import { Button as SharedButton } from "@phiaco/phia-ui";
import { ButtonProps } from "@phiaco/phia-ui/dist/components/Button/Button";

interface Props {
  eventName: MixpanelEventName | null;
  eventProperties?: LogEventProperties;
}

const Button: React.FC<Props & ButtonProps> = ({
  eventName,
  eventProperties,
  onClick,
  ...props
}) => {
  const { logMixpanelEvent } = useAnalytics();
  return (
    <SharedButton
      onClick={(e: React.SyntheticEvent<HTMLButtonElement>) => {
        onClick?.(e);
        if (eventName) {
          logMixpanelEvent({
            name: eventName,
            properties: eventProperties,
          });
        }
      }}
      {...props}
    />
  );
};

export default Button;
